import { io, Socket } from "socket.io-client";
import { Subject } from "rxjs";
import {
  ArbitrageItem,
  ArbitrageItemEntity,
  ExchangeInfo,
  RequestInfo,
} from "@/service/arbitrage-checker/ArbitrageTypes";
import { Dictionary } from "ccxt";

export class ArbitrageHandler {
  private socket?: Socket;
  private rooms: Array<string> = [];
  connection$ = new Subject<boolean>();
  items$ = new Subject<ArbitrageItem[]>();
  infos$ = new Subject<Record<string, Record<string, { count: number; maxDepth: number }>>>();
  exchangeInfos$ = new Subject<ExchangeInfo[]>();
  exchangeInfosAllTickers$ = new Subject<ExchangeInfo[]>();
  exchangeInfosAllOrderBook$ = new Subject<ExchangeInfo[]>();
  requestInfosByExchange$ = new Subject<Dictionary<RequestInfo[]>>();
  history$ = new Subject<ArbitrageItemEntity[]>();

  async start(orderMode = false) {
    const path = !orderMode ? "/arbi-socket.io/" : "/arbi-order-socket.io/";
    this.socket = io({ path, transports: ["websocket"] });

    const connectPromise = new Promise<void>((resolve) => {
      this.socket?.on("connect", () => {
        this.connection$.next(true);
        if (this.rooms.length) {
          //console.log("Joining room:", this.rooms.join(", "));
          for (const room of this.rooms) {
            this.socket?.emit("join", room);
          }
        }
      });
      resolve();
    });

    this.socket.on("disconnect", () => {
      this.connection$.next(false);
    });

    this.socket.on("items", (items) => {
      this.items$.next(items);
    });

    this.socket.on("infos", (infos) => {
      this.infos$.next(infos);
    });

    this.socket.on("updateExchangeInfo", (exchangeInfos: ExchangeInfo[]) => {
      this.exchangeInfos$.next(exchangeInfos);
    });
    this.socket.on("updateExchangeInfoAllTickers", (exchangeInfos: ExchangeInfo[]) => {
      this.exchangeInfosAllTickers$.next(exchangeInfos);
    });
    this.socket.on("updateExchangeInfoAllOrderBook", (exchangeInfos: ExchangeInfo[]) => {
      this.exchangeInfosAllOrderBook$.next(exchangeInfos);
    });
    this.socket.on("requestInfosByExchange", (requestInfosByExchange) => {
      this.requestInfosByExchange$.next(requestInfosByExchange);
    });
    this.socket.on("history", (history: ArbitrageItemEntity[]) => {
      this.history$.next(history);
    });
    await connectPromise;
  }

  joinRoom(room: string) {
    if (this.socket?.connected) {
      this.socket?.emit("join", room);
      //console.log("Joined room:", room);
    }

    this.rooms.push(room);
  }

  leaveRoom(room: string) {
    if (this.socket?.connected) {
      this.socket?.emit("leave", room);
      //console.log("Leave room:", room);
    }

    this.rooms = this.rooms.filter((_room) => _room !== room);
  }

  stop() {
    //console.log("Leaving room:", this.rooms.join(", "));
    for (const room of this.rooms) {
      this.socket?.emit("leave", room);
    }
    this.socket?.removeAllListeners("connect");
    this.socket?.disconnect();
  }

  showItem(item: ArbitrageItem) {
    this.socket?.emit("showItem", item.usdSymbol);
  }

  hideItem(item: ArbitrageItem) {
    this.socket?.emit("hideItem", item.usdSymbol);
  }

  setShowOnlyProfitableItems(room: string, value: boolean) {
    if (!this.socket?.connected) {
      this.socket?.once("connect", () => {
        this.setShowOnlyProfitableItems(room, value);
      });
      return;
    }

    this.socket?.emit("setShowOnlyProfitableItems", room, value);
  }

  getHistoryGroups(
    minimumProfit: number,
    minimumProfitPercent: number,
    tiers: number[],
    durationHours: number,
    skipHours: number
  ) {
    this.socket?.emit("getHistoryGroups", minimumProfit, minimumProfitPercent, tiers, durationHours, skipHours);
  }

  getHistoryItems(
    groupId: number,
    minimumProfit: number,
    minimumProfitPercent: number,
    durationHours: number,
    skipHours: number
  ) {
    this.socket?.emit("getHistoryItems", groupId, minimumProfit, minimumProfitPercent, durationHours, skipHours);
  }

  destroy() {
    this.socket?.emit("destroy");
  }
}
