
import Vue from "vue";
import Component from "vue-class-component";
import { ArbitrageHandler } from "@/service/arbitrage-checker/ArbitrageHandler";
import { ECharts } from "echarts";
import { mdiRefresh } from "@mdi/js";
import { ECOption } from "@/plugins/vue-echarts-ts";
import DateDisplay from "@/components/ArbitrageHistory/DateDisplay.vue";
import { Watch } from "vue-property-decorator";

@Component({
  components: { DateDisplay },
})
export default class ArbitrageHistory extends Vue {
  arbitrageHandler?: ArbitrageHandler;
  minimumProfit = 50;
  minimumProfitPercent = 5;
  tiers: number[] = [3, 4];
  durationHours = 24;
  skipHours = 0;

  selectedGroup: any = null;
  loading: boolean | null = false;

  durationHourItems = [
    { value: 1, text: "last 1 hour" },
    { value: 3, text: "last 3 hours" },
    { value: 8, text: "last 8 hours" },
    { value: 24, text: "last day" },
    { value: 72, text: "last 3 days" },
    { value: 168, text: "last week" },
    { value: 720, text: "last month" },
  ];
  historyGroups: any[] = [];

  eChartInstance!: ECharts;
  icons = {
    mdiRefresh: mdiRefresh,
  };

  // groupBy = (key: any) => (array: any[]) =>
  //   array.reduce((objectsByKeyValue, obj) => {
  //     const value = obj[key];
  //     const array = objectsByKeyValue[value] ?? [];
  //     array.push(obj);
  //     objectsByKeyValue[value] = array;
  //     return objectsByKeyValue;
  //   }, {});

  // indexBy = (list: any[], iteratee: any, context?: any) => {
  //   return list.reduce((map, obj) => {
  //     const key = typeof iteratee === "string" ? obj[iteratee] : iteratee.call(context, obj);
  //     map[key] = obj;
  //     return map;
  //   }, {});
  // };

  @Watch("skipHours")
  onSkipHours() {
    this.getHistoryGroups();
  }

  async created() {
    this.arbitrageHandler = new ArbitrageHandler();
    await this.arbitrageHandler.start();
    this.arbitrageHandler.history$.subscribe((response: any) => {
      (this.$refs["date-display"] as DateDisplay).calc();

      this.loading = false;
      if (response.groups) {
        const groups = response.groups.filter((group: any) => group.durationSec > 1);
        groups.sort((a: any, b: any) => {
          return a.maxProfit > b.maxProfit ? -1 : 1;
        });
        this.historyGroups = groups;
        if (groups.length) {
          this.showGroup(groups[0]);
        }

        //@ts-ignore
        this.options.yAxis[0].min = this.minimumProfit;
        //@ts-ignore
        //this.options.xAxis.min = Date.now() - this.durationHours * 3600 * 1000;
        //@ts-ignore
        //this.options.xAxis.max = Date.now();
      }
      if (response.items) {
        const items = response.items;
        //@ts-ignore
        this.options.dataset.source = items.map((item: any) => [
          new Date(item[0]),
          item[1],
          item[2],
          Math.round((item[1] / item[2]) * 10000) / 100,
          item[3] !== 0 ? Math.round(item[3] / 1000) : undefined,
          item[4] !== 0 ? Math.round(item[4] / 1000) : undefined,
        ]);
        //this.options.series[0].name = group.key;
        //@ts-ignore
        this.options.title.text = this.getTitle();
        //@ts-ignore
        this.options.title.subtext = this.getSubtitle();
        if (this.eChartInstance) {
          this.eChartInstance.dispatchAction({ type: "dataZoom", start: 0, end: 100 });
        }
      }
    });
    this.getHistoryGroups();
  }

  showGroup(group: any) {
    this.selectedGroup = group;
    this.getHistoryItems(group.id);
  }

  getHistoryGroups() {
    this.loading = null;
    setTimeout(() => {
      if (this.loading === null) {
        this.loading = true;
      }
    }, 300);
    this.arbitrageHandler?.getHistoryGroups(
      this.minimumProfit,
      this.minimumProfitPercent,
      this.tiers,
      this.durationHours,
      this.skipHours
    );
  }

  getHistoryItems(groupId: number) {
    this.loading = null;
    setTimeout(() => {
      if (this.loading === null) {
        this.loading = true;
      }
    }, 300);
    this.arbitrageHandler?.getHistoryItems(
      groupId,
      this.minimumProfit,
      this.minimumProfitPercent,
      this.durationHours,
      this.skipHours
    );
  }

  private options: ECOption = {
    xAxis: {
      type: "time",
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      //min: Date.now() - this.durationHours * 3600 * 1000,
      //max: Date.now(),
    },
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
        axisTick: {
          show: false,
        },
      },
      {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
      },
    ],
    textStyle: {
      fontFamily: "Roboto, sans-serif",
    },
    title: {
      text: "",
      subtext: "",
      left: "center",
      top: 10,
      itemGap: 6,
      textStyle: {
        fontWeight: "normal",
        color: "#000",
      },
      subtextStyle: {
        color: "#666",
        fontSize: 14,
      },
    },
    grid: {
      top: 65,
      bottom: 80,
      left: 60,
      right: 80,
    },
    dataset: {
      source: [],
    },
    series: [
      {
        encode: {
          x: 0,
          y: 1,
        },
        type: "bar",
        name: "profit",
        barWidth: "100%",
        large: true,
        sampling: "max",
        z: 30,
      },
      {
        encode: {
          x: 0,
          y: 2,
        },
        yAxisIndex: 1,
        type: "line",
        name: "capital",
        showSymbol: false,
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#FFE0B2", // color at 0%
              },
              {
                offset: 1,
                color: "white", // color at 100%
              },
            ],
            global: false, // default is false
          },
        },
        z: 20,
      },
      {
        type: "line",
        name: "profit(%)",
        z: 10,
        encode: {
          x: 0,
          y: 3,
        },
      },
      {
        type: "line",
        name: "buyItem age",
        showSymbol: false,
        z: 40,
        encode: {
          x: 0,
          y: 4,
        },
      },
      {
        type: "line",
        name: "sellItem age",
        showSymbol: false,
        z: 41,
        encode: {
          x: 0,
          y: 5,
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      position: function (pt: any) {
        return [pt[0], "-15%"];
      },
      transitionDuration: 0,
    },
    dataZoom: [
      { type: "inside" },
      {
        type: "slider",
        //show: true,
        bottom: 20,
        start: 0,
        end: 100,
        handleIcon:
          "path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
        handleSize: "105%",
      },
    ],
    color: ["#2196F3", "#FFA726", "#2196F3", "red", "red"],
  };

  private getTitle() {
    const group = this.selectedGroup;
    return group.currencyCode + ", tier " + group.tier;
  }

  private getSubtitle() {
    const group = this.selectedGroup;
    return group.buyExchange + " => " + group.sellExchange;
  }

  private updateInstance(eChartInstance: ECharts) {
    this.eChartInstance = eChartInstance;
  }

  destroyed() {
    this.arbitrageHandler?.stop();
  }
}
