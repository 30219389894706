
import Vue from "vue";
import Component from "vue-class-component";
import { mdiCalendarToday, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import dayjs, { Dayjs } from "dayjs";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ArbitrageHistory extends Vue {
  @Prop()
  durationHours?: number;
  @Prop()
  skipHours?: number;

  fromDate: Dayjs | null = null;
  toDate: Dayjs | null = null;
  dateFormat = "YYYY-MM-DD HH:mm:ss";

  icons = {
    mdiChevronLeft: mdiChevronLeft,
    mdiChevronRight: mdiChevronRight,
    mdiCalendarToday: mdiCalendarToday,
  };

  calc() {
    this.fromDate = dayjs(Date.now() - (this.skipHours ?? 0) * 3600000 - (this.durationHours ?? 0) * 3600000);
    this.toDate = dayjs(Date.now() - (this.skipHours ?? 0) * 3600000);
  }

  clickPrev() {
    let skipHours = this.skipHours ?? 0;
    skipHours += this.durationHours ?? 0;
    this.$emit("update:skipHours", skipHours);
  }

  clickNext() {
    let skipHours = this.skipHours ?? 0;
    skipHours -= this.durationHours ?? 0;
    if (skipHours < 0) {
      skipHours = 0;
    }
    this.$emit("update:skipHours", skipHours);
  }

  clickToday() {
    this.$emit("update:skipHours", 0);
  }
}
